import { FC } from 'react'
import { useQuery } from '@apollo/client'

import { ArtistsWithTicketsVipQuery, ArtistsWithTicketsVipQueryVariables } from 'gql'
import { getImageUrl } from 'utils'
import { Card, Typography, Grid, Heading } from 'components/ui'
import { STAR } from 'routes/paths'
import Carousel from 'components/carousel'
import { useScreenClass } from '~hooks'
import { STARS_WITH_VIP_QUERY } from 'gql/queries'

type settingsVipOrder = Array<{
  id: string
  order: number
}>

type StarsProps = {
  title?: string
  idsAndOrder: settingsVipOrder
}

const StarsTicketsVIP: FC<StarsProps> = ({
  idsAndOrder: idsOrdered,
  title = 'Trending VIP Events',
}) => {
  const { isMobile } = useScreenClass()

  const {
    data: stars,
    loading,
    fetchMore,
  } = useQuery<ArtistsWithTicketsVipQuery, ArtistsWithTicketsVipQueryVariables>(
    STARS_WITH_VIP_QUERY,
    {
      variables: {
        first: 10,
        status: 'public',
        activeArtworks: true,
        ids: idsOrdered?.map((i) => i.id).join(','),
      },
      skip: !idsOrdered?.length,
      notifyOnNetworkStatusChange: true,
    }
  )

  const items = idsOrdered
    .map((ids) => {
      const obj = stars?.allArtists.edges.find((sAll) => ids.id === sAll?.node.id)?.node
      if (!obj) {
        return null
      }

      return {
        link: STAR(obj.slug),
        t: obj.fullName,
        id: obj.id,
        image: obj.file,
      }
    })
    .filter(Boolean)

  const pageInfo = stars?.allArtists.pageInfo

  const loadMore = pageInfo?.hasNextPage
    ? () => fetchMore({ variables: { after: pageInfo.endCursor, first: 10 } })
    : null

  return (
    <Grid gap={18}>
      <Grid
        gridAutoFlow={isMobile ? 'row' : 'column'}
        justifyContent="start"
        alignItems="center"
        gap={8}
      >
        <Heading Tag="span" colorGlow="g-green" size="span" uppercase color="g-white">
          {title}
        </Heading>
      </Grid>
      {(!items || !items.length) && !loading && (
        <Typography.Text color="light-gray">No VIP events at this time.</Typography.Text>
      )}
      <Carousel<typeof items[0]>
        dataSource={items}
        renderItem={({ link, t, id, image }) => (
          <Card
            key={id}
            imageSrc={getImageUrl(image, 400, 300)}
            link={link}
            linkTitle="Buy now"
            title={t}
          />
        )}
        loading={loading}
        fetchMore={loadMore}
      />
    </Grid>
  )
}

export default StarsTicketsVIP
