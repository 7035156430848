import getCurrentTimeByTimeZone from '~utils/getCurrentTimeByTimeZone'
import parseDateStringToObject from '~utils/parseDateStringToObject'

/**
 * Determines whether an event is available to be held at a venue based on its start time,
 * the current time, and a defined cutoff time.
 *
 * ### Conditions for Availability:
 * 1. The event's start date and time must be valid.
 * 2. The event's venue must have a valid timezone.
 * 3. The event must start after the current time.
 * 4. The event must start before the cutoff time (9:00 PM) on the same day.
 *
 * @param {Object} event - The event details.
 * @param {string} event.eventStartDateLocal - The event's local start date and time in ISO format.
 * @param {Object} event.venue - The venue details.
 * @param {string} event.venue.timeZone - The venue's timezone (e.g., 'America/New_York').
 * @param {string} [currentTime] - The current time in ISO format. If omitted, the system's current time will be used (optional).
 *
 * @returns {boolean} - `true` if the event is available at the venue, otherwise `false`.
 *
 * @example
 * const event = {
 *   eventStartDateLocal: '2025-02-25T19:00:00',
 *   venue: { timeZone: 'America/New_York' }
 * };
 *
 * const isAvailable = checkEventIsAvailableInVenue(event);
 * console.log(isAvailable); // true or false based on the current time.
 *
 * // Using a specific current time
 * const isAvailableAtSpecificTime = checkEventIsAvailableInVenue(event, '2025-02-25T18:00:00');
 * console.log(isAvailableAtSpecificTime); // true or false based on the provided current time.
 */
export default function checkEventIsAvailableInVenue(event, currentTime?: string) {
  if (!event?.eventStartDateLocal || !event?.venue?.timeZone) {
    return false
  }

  const CUT_HOUR = 21
  const CUT_MINUTE = 0

  const currentDate = parseDateStringToObject(
    getCurrentTimeByTimeZone(event.venue.timeZone, currentTime)
  )
  const eventDate = parseDateStringToObject(event.eventStartDateLocal)

  if (
    eventDate.year < currentDate.year ||
    (eventDate.year === currentDate.year && eventDate.month < currentDate.month) ||
    (eventDate.year === currentDate.year &&
      eventDate.month === currentDate.month &&
      eventDate.day < currentDate.day)
  ) {
    return false
  }

  if (
    eventDate.year > currentDate.year ||
    eventDate.month > currentDate.month ||
    eventDate.day > currentDate.day
  ) {
    return true
  }

  if (
    currentDate.hour < CUT_HOUR ||
    (currentDate.hour === CUT_HOUR && currentDate.minute === CUT_MINUTE)
  ) {
    return true
  }

  if (
    currentDate.hour > eventDate.hour ||
    (currentDate.hour === eventDate.hour && currentDate.minute > eventDate.minute) ||
    (currentDate.hour === eventDate.hour &&
      currentDate.minute === eventDate.minute &&
      currentDate.second > eventDate.second)
  ) {
    return false
  }

  return true
}
