import { FC, useMemo } from 'react'
import { useQuery } from '@apollo/client'

import { ArtistsWithTicketsVipQuery, ArtistsWithTicketsVipQueryVariables } from 'gql'
import { getImageUrl } from 'utils'
import { Card, Typography, Grid, Heading } from 'components/ui'
import { STAR } from 'routes/paths'
import Carousel from 'components/carousel'
import { useScreenClass } from '~hooks'
import { GET_FEATURED_STARS, STARS_WITH_VIP_QUERY } from 'gql/queries'

const STARTS_PER_PAGE = 10

type SettingsVipOrder = {
  id: string
  order: number
}

type StarsProps = {
  title?: string
  idsAndOrder: SettingsVipOrder[]
}

const StarsTicketsVIPWithFeaturedStars: FC<StarsProps> = ({
  idsAndOrder,
  title = 'Trending VIP Events',
}) => {
  const { isMobile } = useScreenClass()

  const {
    data: vipStarts,
    loading: vipStartsLoading,
    fetchMore,
  } = useQuery<ArtistsWithTicketsVipQuery, ArtistsWithTicketsVipQueryVariables>(
    STARS_WITH_VIP_QUERY,
    {
      variables: {
        first: STARTS_PER_PAGE,
        status: 'public',
        activeArtworks: true,
        ids: idsAndOrder?.map((i) => i.id).join(','),
      },
      skip: !idsAndOrder?.length,
      notifyOnNetworkStatusChange: true,
    }
  )

  const pageInfo = vipStarts?.allArtists.pageInfo

  const loadMore = pageInfo?.hasNextPage
    ? () => {
        fetchMore({ variables: { after: pageInfo.endCursor, first: STARTS_PER_PAGE } })
      }
    : null

  const { data: featuredStarts, loading: featuredStartsLoading } = useQuery(GET_FEATURED_STARS, {
    fetchPolicy: 'network-only',
    skip: !pageInfo || pageInfo.hasNextPage === true,
    variables: {
      ids: idsAndOrder?.map((i) => i.id).join(','),
    },
  })

  const isLoading = featuredStartsLoading || vipStartsLoading

  const items = useMemo(() => {
    const response = []

    idsAndOrder.forEach((ids) => {
      const obj = vipStarts?.allArtists.edges.find((sAll) => ids.id === sAll?.node.id)?.node
      if (!obj) return
      response.push({
        link: STAR(obj.slug),
        t: obj.fullName,
        id: obj.id,
        image: obj.file,
      })
    })

    const allArtists = featuredStarts?.allArtists?.edges || []

    allArtists.forEach(({ node }) => {
      response.push({
        link: STAR(node.slug),
        t: node.fullName,
        id: node.id,
        image: node.file,
      })
    })

    return response
  }, [vipStarts, idsAndOrder, featuredStarts])

  return (
    <Grid gap={18}>
      <Grid
        gridAutoFlow={isMobile ? 'row' : 'column'}
        justifyContent="start"
        alignItems="center"
        gap={8}
      >
        <Heading Tag="span" colorGlow="g-green" size="span" uppercase color="g-white">
          {title}
        </Heading>
      </Grid>
      {(!items || !items.length) && !isLoading && (
        <Typography.Text color="light-gray">No VIP events at this time.</Typography.Text>
      )}
      <Carousel<typeof items[0]>
        dataSource={items}
        renderItem={({ link, t, id, image }) => (
          <Card
            key={id}
            imageSrc={getImageUrl(image, 400, 300)}
            link={link}
            linkTitle="Buy now"
            title={t}
          />
        )}
        loading={isLoading}
        fetchMore={loadMore}
      />
    </Grid>
  )
}

export default StarsTicketsVIPWithFeaturedStars
