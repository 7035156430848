const getCurrentTimeByTimeZone = (timeZone: string, currentTime?: string): string => {
  if (currentTime) return currentTime

  const date = new Date().toLocaleString('en-US', {
    timeZone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  })
  return date.replace(/(\d+)\/(\d+)\/(\d+), (\d+):(\d+):(\d+)/, '$3-$1-$2T$4:$5:$6')
}

export default getCurrentTimeByTimeZone
