import { useQuery } from '@apollo/client'

import Carousel from 'components/carousel'
import { STAR } from 'routes/paths'
import { getImageUrl } from '~utils'
import { Card, Wrapper } from '~ui'
import { ArtistNode } from 'gql'
import { GET_FEATURED_STARS } from 'gql/queries'

const FeaturesStars = ({ title }) => {
  const { data, loading } = useQuery(GET_FEATURED_STARS, { fetchPolicy: 'network-only' })

  return (
    <Wrapper style={{ paddingBlock: '80px' }}>
      <Carousel<Pick<ArtistNode, 'fullName' | 'file' | 'slug'>>
        dataSource={data?.allArtists.edges.map(({ node }) => node)}
        title={title}
        renderItem={({ file, slug, fullName }) => (
          <Card
            key={slug}
            imageSrc={getImageUrl(file, 300, 200)}
            linkTitle="Get Tickets"
            title={fullName}
            link={STAR(slug)}
          />
        )}
        loading={loading}
      />
    </Wrapper>
  )
}

export default FeaturesStars
