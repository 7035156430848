import { Wrapper } from '~ui'
import StarsTicketsVIPWithFeaturedStars from '../../../tickets/vip-tickets-with-featured-stars'

const VipTicketsWithFeaturedStars = (settings) => {
  const { 'vip-stars-order': idsAndOrder, title } = settings

  return (
    <Wrapper>
      <StarsTicketsVIPWithFeaturedStars idsAndOrder={idsAndOrder} title={title} />
    </Wrapper>
  )
}

export default VipTicketsWithFeaturedStars
