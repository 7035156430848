type DateObj = {
  year: number
  month: number
  day: number
  hour: number
  minute: number
  second: number
}

function parseDateToObject(date: Date): DateObj {
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
    hour: date.getHours(),
    minute: date.getMinutes(),
    second: date.getSeconds(),
  }
}

export default function parseDateStringToObject(date: string): DateObj {
  try {
    return parseDateToObject(new Date(date))
  } catch (error) {
    return parseDateToObject(new Date())
  }
}
